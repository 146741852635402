import React from 'react'
import logos from '../assets/img/logos.webp';
export const Servicios_Form_2 = () => {
    return (
        <div className="container-fluid g-0">
            <div className="servicios_box servicio_sp">
                <span className="span_title_services">
                    Sportbook
                </span>
            </div>
            <div className="servicios_box live_casino">
                <span className="span_title_services">
                    Live Casino
                </span>
            </div>
            <div className="servicios_box online_casino">
                <span className="span_title_services">
                    Online Casino
                </span>
            </div>
            <div className="servicios_box live_betting">
                <span className="span_title_services">
                    Live Betting
                </span>
            </div>
            <div className="servicios_box races">

                <span className="span_title_services">
                    Horses
                </span>
            </div>
            <div className="servicios_box poker">
                <span className="span_title_services">
                    Poker
                </span>
            </div>
            <div className="servicios_box prop_builder">
                <span className="span_title_services">
                    Prop Builder
                </span>
            </div>
            <div className="servicios_box call_center">
                <span className="span_title_services">
                    Call Center
                </span>
            </div>
            <div className='col-12 text-center py-2 float-end'>
                <img className='img-fluid' src={logos} alt="logos of sports"></img>
            </div>
        </div>
    )
}
