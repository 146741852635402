import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.webp';


export default class header extends Component {
    render() {
        return (
            <div className='header'>
                <div className="row g-0">
                    <div className="col-xl-3 d-flex justify-content-center align-items-center py-1">
                        <Link to="/home">
                            <img className="img-fluid" src={logo} alt="logo"></img>
                        </Link>
                    </div>
                    <div className="col-lg-4">

                    </div>
                    <div className="col-lg-5 d-flex justify-content-center align-items-center px-lg-2">
                        <form action="https://wager.vipsports247.com/Qubic/SecurityPage.php" method="post" name="client login">
                            <div className="row p-2 text-white textShadow">
                                <div className="col-4 text-center">
                                    <input type="text" name="customerID" placeholder="Customer" class="form-control" autofocus="" />
                                </div>
                                <div className="col-4 text-center">
                                    <input type="password" name="Password" placeholder="Password" class="form-control" />
                                    <input type="hidden" name="action" value="1" />
                                </div>
                                <div className="col-4 text-center">
                                    <button type="submit" className="btn btn_login">Login</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        )
    }
}
